<template>
  <g :fill="color">
    <path
      d="M-.432 4.086c.204-.239.563-.267.802-.063.783.67 1.627 1.18 2.518 1.475l.287.088c1.363.38 2.843.31 4.26-.042l.348-.092c.264-.076.464-.145.584-.193.291-.118.623.023.74.314.118.291-.022.623-.314.74l-.222.083c-.216.076-.508.164-.863.252-1.59.395-3.258.475-4.839.034-.114-.032-.227-.067-.34-.104C1.486 6.23.52 5.647-.369 4.888c-.24-.204-.267-.563-.063-.802zM18.629 4.023c.239-.204.598-.176.802.063.204.239.175.598-.064.802-.889.76-1.856 1.343-2.899 1.69-.111.037-.224.071-.338.103-1.58.442-3.25.362-4.84-.033-.497-.123-.87-.248-1.085-.335-.291-.117-.432-.449-.314-.74.117-.291.449-.432.74-.314.169.068.492.176.933.285 1.416.352 2.896.423 4.26.042.096-.027.191-.056.286-.088.89-.296 1.734-.805 2.519-1.475z"
      transform="translate(1 1)"
    />
    <path
      d="M3.442.28C4.7-.87 6.638-.843 7.862.31L8 .45 9.423 1.95h.151l1.422-1.5C12.164-.791 14.1-.907 15.407.154l.148.128c.066.06.123.116.17.168 1.045 1.102 1.249 2.736.531 4.045-.031.058-.068.118-.108.178-.63.958-1.568 1.664-2.634 1.97-.515.147-1.033.204-1.552.15-.312-.033-.54-.312-.507-.625.033-.312.313-.539.625-.506.365.038.739-.003 1.12-.112.8-.23 1.515-.768 2-1.504l.057-.095c.483-.88.346-1.977-.363-2.725-.03-.032-.064-.066-.107-.105-.805-.738-2.044-.73-2.841-.008l-.123.12-1.592 1.68c-.086.09-.199.149-.32.169l-.093.007H9.18c-.125 0-.246-.04-.344-.115l-.069-.062L7.173 1.23C6.386.393 5.058.344 4.21 1.12l-.113.112c-.703.741-.84 1.837-.36 2.713.019.033.04.067.063.103.482.733 1.197 1.27 1.996 1.5.383.11.757.15 1.122.113.312-.033.592.194.625.506.032.313-.195.592-.507.625-.519.054-1.037-.003-1.553-.15-1.066-.306-2.005-1.013-2.631-1.967-.039-.057-.075-.116-.11-.179-.718-1.311-.515-2.945.526-4.043.055-.06.113-.116.174-.172z"
      transform="translate(1 1)"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
